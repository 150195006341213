import React, { useEffect, useState, useCallback } from "react";
import { Button, SpaceBetween, Spinner } from "@cloudscape-design/components";
import cipraLogo from '../../../res/ProviderLogos/cipra_logo.jpg';
import { AuthData } from "../../../data/AuthData";
import { RegistrationData } from "../../../data/RegistrationData";
import { support_contact } from "../../../res/support";
import { TextInput } from '../../../components/TextInput';
import { DropdownBox } from '../../../components/DropdownBox';
import { validateInput, validateMultiSelectInput } from '../../../utility-function/InputValidation';
import { Multiselect } from "../../../components/Multiselect";

const support: JSX.Element = support_contact;

interface JsonData {
    providers: { provider: string, name: string }[];
    programs: { program: string, name: string, country: string }[];
    languages: { language_id: string, language_name: string }[];
}

export function AdminRegistration(): JSX.Element {
    const [emailValue, setEmailValue] = useState<string>('');
    const [showEmailMessage, setShowEmailMessage] = useState<boolean>(false)

    const [passwordValue, setPasswordValue] = useState<string>('');
    const [showPasswordMessage, setShowPasswordMessage] = useState<boolean>(false)

    const [signInTip, setSignInTip] = useState<JSX.Element | undefined>(undefined);
    const [message, setMessage] = useState<JSX.Element | undefined>(undefined);
    const [signedIn, setSignedIn] = useState<boolean>(false);
    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false);
    const [response, setResponse] = useState<JsonData | null>(null);
    const [selectedProvider, setSelectedProvider] = useState<string>('');
    const [showPrvidermMessage, setShowProviderMessage] = useState<boolean>(false)
    const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
    const [showProgramMessage, setShowProgramMessage] = useState<boolean>(false)
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');
    const [showLanguageMessage, setShowLanguageMessage] = useState<boolean>(false)
    const [newEmailValue, setNewEmailValue] = useState<string>('');
    const [showNewEmailMessage, setShowNewEmailMessage] = useState<boolean>(false)
    const [subscription, setSubscription] = useState<string>('');
    const [showSubscriptionMessage, setShowSubscriptionMessage] = useState<boolean>(false)


    const submit = useCallback((): void => {
        setSubmitWaiting(true);
        const isEmailValid = validateInput(emailValue);
        const isPasswordValid = validateInput(passwordValue);
        setShowEmailMessage(!isEmailValid);
        setShowPasswordMessage(!isPasswordValid);
        if (!isPasswordValid || !isEmailValid) {
            setSubmitWaiting(false)
            return;
        }

        AuthData.instance.userAuth(
            emailValue,
            passwordValue,
            (data) => {
                setResponse(data);
                setSubmitWaiting(false);
                setSignedIn(true);
            },
            () => {
                setSubmitWaiting(false);
                setSignInTip(
                    <div>
                        <p>Log In Failed</p>
                        {support}
                    </div>
                );
            }
        );
    }, [emailValue, passwordValue]);

    const selectionSubmit = useCallback((): void => {
        setSubmitWaiting(true);

        const isProviderValid = validateInput(selectedProvider);
        const isProgramValid = validateMultiSelectInput(selectedPrograms);
        const isLanguageValid = validateInput(selectedLanguage);
        const isNewEmailValid = validateInput(newEmailValue);
        let isSubscriptionValid = true;
        if (emailValue === "support.in@cipra.ai") {
            isSubscriptionValid = validateInput(subscription);
        }
        setShowNewEmailMessage(!isNewEmailValid)
        setShowProviderMessage(!isProviderValid)
        setShowProgramMessage(!isProgramValid)
        setShowLanguageMessage(!isLanguageValid)
        setShowSubscriptionMessage(!isSubscriptionValid)
        console.log(isNewEmailValid)
        console.log(isProviderValid)
        console.log(isProgramValid)
        console.log(isLanguageValid)
        console.log(isSubscriptionValid)

        if (!isProviderValid || !isProgramValid || !isLanguageValid || !isNewEmailValid || !isSubscriptionValid) {
            setSubmitWaiting(false);
            return;
        }

        RegistrationData.instance.emailExist(
            newEmailValue,
            () => {
                RegistrationData.instance.sendEmail(
                    newEmailValue,
                    selectedProvider,
                    selectedPrograms,
                    subscription,
                    selectedLanguage,
                    () => {
                        setSubmitWaiting(false);
                        setMessage(
                            <div style={{ color: 'green' }}>
                                Registration Link has been sent to <b>{newEmailValue}</b>.
                            </div>
                        );
                    },
                    () => {
                        setSubmitWaiting(false);
                        setMessage(
                            <div>
                                Request cannot be processed at the moment.
                            </div>
                        );
                    }
                );
            },
            () => {
                setSubmitWaiting(false);
                setMessage(
                    <div>
                        <p>This email address <b>{newEmailValue} has already been registered.</b></p>
                        {support}
                    </div>
                );
            }
        );
    }, [selectedProvider, selectedPrograms, selectedLanguage, newEmailValue, subscription, emailValue]);

    useEffect(() => {
        const enterKeyDown = (event: KeyboardEvent) => {
            if (event.key !== 'Enter') return;
            submit();
        };

        document.addEventListener("keydown", enterKeyDown, false);

        return () => {
            document.removeEventListener("keydown", enterKeyDown, false);
        };
    }, [submit]);

    return (
        <div className="center-screen">
            <div className="glow-border page-container" style={{ maxWidth: "330px" }}>
                <SpaceBetween size='m' direction='vertical'>
                    <img src={cipraLogo} alt='cipra logo' width='90%' className='line-align-center' />
                    {
                        !signedIn &&
                        <SpaceBetween size='m' direction='vertical'>
                            <h2 className='line-align-center'>Log In</h2>
                            <TextInput label="Email" value={emailValue} onChange={setEmailValue} type="email" placeholder="Email Address" showMessage = {showEmailMessage}/>
                            <TextInput label="Password" value={passwordValue} onChange={setPasswordValue} type="password" placeholder="Password" showMessage = {showPasswordMessage}/>
                            {signInTip && <p className='tip-text'>{signInTip}</p>}
                            <div className='line-align-center'>
                                <Button variant='primary' onClick={submit} disabled={submitWaiting}>
                                    {submitWaiting && <Spinner />}
                                    Log In
                                </Button>
                            </div>
                        </SpaceBetween>
                    }
                    {signedIn && response &&
                        <SpaceBetween size='m' direction='vertical'>
                            <DropdownBox
                                label="Select a Provider"
                                options={response.providers.map(provider => ({ value: provider.provider, label: provider.name }))}
                                selectedValue={selectedProvider}
                                onChange={setSelectedProvider}
                                showMessage = {showPrvidermMessage}
                            />
                            <Multiselect
                                label="Select Programs"
                                options={response.programs.map(program => ({ value: program.program, label: `${program.name} (${program.program})` }))}
                                selectedValue={selectedPrograms}
                                onChange={setSelectedPrograms}
                                showMessage = {showProgramMessage}
                                />
                            <DropdownBox
                                label="Select a Language"
                                options={response.languages.map(language => ({ value: language.language_id, label: language.language_name }))}
                                selectedValue={selectedLanguage}
                                onChange={setSelectedLanguage}
                                showMessage = {showLanguageMessage}
                            />
                            {emailValue === "support.in@cipra.ai" && (
                                <DropdownBox
                                    label="Select Subscription Type"
                                    options={[
                                        { value: "6", label: "6 months" },
                                        { value: "12", label: "12 months" }
                                    ]}
                                    selectedValue={subscription}
                                    onChange={setSubscription}
                                    showMessage = {showSubscriptionMessage}
                                />
                            )}
                            <TextInput 
                                label="Enter Patient's Email" 
                                value={newEmailValue} 
                                onChange={setNewEmailValue} 
                                type="email" 
                                placeholder="Email Address" 
                                showMessage = {showNewEmailMessage}
                            />
                            {message && <p className='tip-text'>{message}</p>}
                            <div className='line-align-center'>
                                <Button variant='primary' onClick={selectionSubmit} disabled={submitWaiting}>
                                    {submitWaiting && <Spinner />}
                                    Confirm
                                </Button>
                            </div>
                        </SpaceBetween>
                    }
                </SpaceBetween>
            </div>
        </div>
    );
}