import React from 'react';
import {useParams} from "react-router";

interface ResourceProps {
    resourceName?: string; // make provider optional
}

function getImage(resourceName: string | undefined): string | null {
    // Allow letters, numbers, underscores, hyphens, and dots (for file extensions)
    if (!resourceName || !/^[a-zA-Z0-9._-]+$/.test(resourceName)) {
        // Return null if resourceName is invalid
        return null;
    }

    try {
        return require(`../../res/infographics/${resourceName}`);
    } catch (e) {
        // Return null if the image doesn't exist
        return null;
    }
}

export const renderResource: React.FC<ResourceProps> = ({ resourceName }) => {
    const image = getImage(resourceName);
    return image ? (
        <img src={image} alt={resourceName || 'res'} className='infographic'/>
    ) : (
        <p style={{ color: 'white', textAlign: 'center' }}>Resource does not exist.</p>
    );
};

export function ResourceContainer(): JSX.Element {
    const { resource } = useParams()
    return (
        <div className='infographic_background'>
            {renderResource({ resourceName: resource})}
        </div>
    );
}
