import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ForgetPasswordPage} from "./pages/password-reset/ForgetPasswordPage";
import {PasswordResetNewPasswordPage} from "./pages/password-reset/PasswordResetNewPasswordPage";
import UnsubscribeEntryPage from './pages/unsubscribe/UnsubscribeEntryPage';
import UnsubscribeConfirmationPage from './pages/unsubscribe/UnsubscribeConfirmationPage';
import PatientRegistrationMultiProgram from './pages/new-account/RegistrationPages/PatientRegistrationMultiProgram';
import QuestionnairePagesContainer from './pages/new-account/QuestionPages/Utils/QuestionnairePagesContainer';
import { AdminRegistration } from './pages/new-account/RegistrationPages/AdminRegistration';
import {support_contact} from "./res/support"
import { OnBoardingPage } from './pages/onboarding/OnBoardingPage';
import PatientRegistrationSingleProgram from "./pages/new-account/RegistrationPages/PatientRegistrationSingleProgram";
import {ResourceContainer} from "./pages/infographics/ResourceContainer";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const support: JSX.Element = support_contact
function NotValidPage(): JSX.Element {
  return <div className='center-screen' style={{marginTop: '30px'}}>
    <div>
      This is not a valid registration link.
    </div>
    {support}
  </div>
}

root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/signup' element={<NotValidPage />} />
            <Route path='/signup/:provider/' element={<NotValidPage />} />
            <Route path='/forgotPassword' element={<ForgetPasswordPage />}></Route>
            <Route path='/onboarding' element = {<OnBoardingPage/>}></Route>
            <Route path='/notValid' element={<NotValidPage />} />
            <Route path='/unsubscribe' element={<UnsubscribeEntryPage />} />
            <Route path='/confirmUnsubscribe/:token' element={<UnsubscribeConfirmationPage />} />
            <Route path='/resetPassword/:token' element={<PasswordResetNewPasswordPage />}></Route>
            <Route path='/generateregistrationurl/:provider/:program' element={<PatientRegistrationSingleProgram />} />
            <Route path='/generateregistrationurl/:provider' element={<PatientRegistrationMultiProgram />} />
            <Route path='/generateregistrationurl' element={<AdminRegistration />} />
            <Route path='/register/:token' element = {<QuestionnairePagesContainer/>}/>
            <Route path='/res/:resource' element = {<ResourceContainer/>}/>

            {/*<Route path='/register-old/:token' element = {<QuestionnairePagesContainer/>}/>*/}
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
