import {Button, Checkbox, SpaceBetween} from "@cloudscape-design/components";
import "../../__pages.css"
import PhoneInput, {CountryData} from 'react-phone-input-2'
import {isPossiblePhoneNumber} from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css'
import {ChangeEvent, useEffect, useState} from "react";
import {RegistrationData} from "../../../data/FormData";
import {Terms} from "../../../res/terms";
import {NewAccountPageTrigger, PagesEnum} from "./Utils/PageTrigger";
import {useLocation} from 'react-router-dom';
import $ from 'jquery';
import {TextInput} from "../../../components/TextInput";

export function QuestionnaireEntryPage(): JSX.Element {
    const location = useLocation();

    const [emailValue, setEmailValue] = useState<string>(RegistrationData.instance.email)
    const [phoneValue, setPhoneValue] = useState<string>(RegistrationData.instance.cell)
    const [passwordValue, setPasswordValue] = useState<string>(RegistrationData.instance.password)
    const [confirmedPasswordValue, setConfirmedPasswordValue] = useState<string>(RegistrationData.instance.password)
    const [policyCheckedValue, setPolicyCheckedValue] = useState<boolean>(false)
    const [passwordMessage, setPasswordMessage] = useState<string | undefined>('')
    const [showPasswordMessage, setShowPasswordMessage] = useState<boolean>(false)
    const [termAgreed, setTermAgreed] = useState<boolean>(true)
    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(true)
    const [requirementsMet, setRequirementsMet] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        special: false
    });
    const termAgreement: JSX.Element = Terms()
    useEffect(() => {
        // Example: Add a click event listener to an element with class 'my-element'
        $('input[type=password]:first').keyup(function () {// only show the password requirement box when the password input is focused
            // keyup code here
        }).focus(function () {
            $('#pswd_info').show();
        }).blur(function () {
            $('#pswd_info').hide();
        });
    }, []); // Empty dependency array means this effect runs only once after initial render

    const handlePasswordChange = (value: string): void => {
        setPasswordValue(value);
        checkPasswordRequirements(value);
        setPasswordMessage(undefined);
    };

    const handleConfirmedPasswordChange = (value: string): void => {
        setConfirmedPasswordValue(value)
        setPasswordMessage(undefined)
    }

    const checkPasswordRequirements = (password: string) => {
        setRequirementsMet({
            length: password.length >= 8 && password.length <= 25,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            digit: /[0-9]/.test(password),
            special: /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password)
        });
    };

    function submit(): void {
        const checkedTermAgreed = policyCheckedValue
        const checkValidPhoneNumber = !!(phoneValue && isPossiblePhoneNumber("+" + phoneValue))
        setValidPhoneNumber(checkValidPhoneNumber)
        setTermAgreed(checkedTermAgreed)
        // check if password meet the requirement
        var passwordMeetRequirement = false
        if (passwordValue.length === 0) {
            setShowPasswordMessage(true)
            setPasswordMessage("Password cann't be empty.")
        } else if (passwordValue.length > 25) {
            setShowPasswordMessage(true)
            setPasswordMessage("Your password is too long. Please choose a password that is no more than 15 characters long")
        } else if (passwordValue.length < 8) {
            setShowPasswordMessage(true)
            setPasswordMessage("Your password is too short. Please choose a password that is at least 8 characters long.")
        } else if (!/[A-Z]/.test(passwordValue)) {
            setShowPasswordMessage(true)
            setPasswordMessage("Password must contain at least one uppercase letter.")
        } else if (!/[a-z]/.test(passwordValue)) {
            setShowPasswordMessage(true)
            setPasswordMessage("Password must contain at least one lowercase letter.")
        } else if (!/\d/.test(passwordValue)) {
            setShowPasswordMessage(true)
            setPasswordMessage("Password must contain at least one numeric digit.")
        } else if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(passwordValue)) {
            setShowPasswordMessage(true)
            setPasswordMessage("Password must contain at least one special character.")
        } else if (passwordValue !== confirmedPasswordValue) {
            setShowPasswordMessage(true)
            setPasswordMessage("Password not the same")
        } else { // all requirements are meet
            setPasswordMessage(undefined)
            setShowPasswordMessage(false)
            passwordMeetRequirement = true
        }

        if (checkedTermAgreed && checkValidPhoneNumber && passwordMeetRequirement) {
            RegistrationData.instance.email = emailValue
            RegistrationData.instance.cell = phoneValue
            RegistrationData.instance.password = passwordValue
            // simpler version of registration
            if (NewAccountPageTrigger.instance.setNewAccountShowPage && location.pathname.startsWith("/register/")) {
                NewAccountPageTrigger.instance.setNewAccountShowPage(PagesEnum.Simple)
            }
            if (NewAccountPageTrigger.instance.setNewAccountShowPage && location.pathname.startsWith("/register-old/")) {
                NewAccountPageTrigger.instance.setNewAccountShowPage(PagesEnum.Second)
            }
        }
    }

    function handlePhoneNumberChange(value: string, data: {} | CountryData, event: ChangeEvent<HTMLInputElement>, formattedValue: string): void {
        setPhoneValue(value)
        setValidPhoneNumber(true)
    }

    return <div>
        <SpaceBetween size='xs' direction='vertical'>
            <h2 className='line-align-center'>Create an account</h2>
            <TextInput
                label="Email"
                value={emailValue}
                onChange={setEmailValue}
                type="email"
                placeholder="Email Address"
                disabled={true}/>
            <SpaceBetween size='m' direction='horizontal'>
                <div>Cell</div>
            </SpaceBetween>
            <PhoneInput
                country={'us'}
                value={phoneValue}
                onChange={handlePhoneNumberChange}
                buttonStyle={{
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    borderTop: '2px solid #9ba7b6',
                    borderBottom: '2px solid #9ba7b6',
                    borderLeft: '2px solid #9ba7b6'
                }}
                inputStyle={{
                    width: '100%',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderTop: '2px solid #9ba7b6',
                    borderBottom: '2px solid #9ba7b6',
                    borderRight: '2px solid #9ba7b6'
                }}
                placeholder="Cellphone number"
            />
            {
                !validPhoneNumber && <p className='tip-text'>Please enter a valid phone number.</p>
            }
            <TextInput
                label="Password"
                value={passwordValue}
                onChange={handlePasswordChange}
                type="password"
                placeholder="password"
                showMessage={showPasswordMessage}
                message={passwordMessage}
            />
            <div id="pswd_info">
                {/* Display password requirements */}
                <h4>Password must meet the following requirements:</h4>
                <ul className="password-requirements">
                    <li className={requirementsMet.length ? 'met' : 'not-met'}>At least 8 characters. At most 25 characters</li>
                    <li className={requirementsMet.uppercase ? 'met' : 'not-met'}>At least one uppercase letter</li>
                    <li className={requirementsMet.lowercase ? 'met' : 'not-met'}>At least one lowercase letter</li>
                    <li className={requirementsMet.digit ? 'met' : 'not-met'}>At least one digit</li>
                    <li className={requirementsMet.special ? 'met' : 'not-met'}>At least one special character</li>
                </ul>
            </div>
            <TextInput
                label="Confirmed Password"
                value={confirmedPasswordValue}
                onChange={handleConfirmedPasswordChange}
                type="password"
                placeholder="Confirmed Password"
                showMessage={showPasswordMessage}
                message={passwordMessage}
            />

            <SpaceBetween size='m' direction='horizontal'>
                <div>Terms of Service</div>
            </SpaceBetween>
            <div style={{
                height: '150px',
                overflow: 'auto',
                borderRadius: '8px',
                border: '2px solid #9ba7b6'
            }}>
                {termAgreement}
            </div>
            <Checkbox onChange={({detail}) => setPolicyCheckedValue(detail.checked)}
                      checked={policyCheckedValue}>
                I have read and agree to the Terms and Conditions, including the <a
                href={'https://www.cipra.ai/privacy-policy'} target={'_blank'} rel="noreferrer">Privacy Policy</a>. I
                agree to receive SMS messages related to the service.
            </Checkbox>
            {
                !termAgreed && <p className='tip-text'>You must agree with the Terms of Service.</p>
            }

            <div className='line-align-center'>
                <Button variant='primary' onClick={submit}>
                    Continue
                </Button>
            </div>
        </SpaceBetween>
    </div>
}
