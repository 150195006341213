import { Button, Input, SpaceBetween } from "@cloudscape-design/components";
import { useState } from "react";
import { DEBUG_MODE, RegistrationData } from "../../../data/FormData";
import { NewAccountPageTrigger, PagesEnum } from "./Utils/PageTrigger";
import { TextInput } from "../../../components/TextInput";
import SingleSelectComponent from "../../../components/SingleSelect";
import { Multiselect } from "../../../components/Multiselect";
import { genderOptions,preExistingConditions,raceList } from "./Questions/questionOptions";
import { validateInput, validateMultiSelectInput } from "../../../utility-function/InputValidation";

export function QuestionnairePage1(): JSX.Element {

    // return <div></div>
    const [firstName, setFirstName] = useState(RegistrationData.instance.firstName)
    const [showFirstNameMessage, setShowFirstNameMessage] = useState<boolean>(false)
    const [lastName, setLastName] = useState(RegistrationData.instance.lastName)
    const [showLastNameMessage, setShowLastNameMessage] = useState<boolean>(false)

    const [genderValue, setGenderValue] = useState(RegistrationData.instance.gender)
    const [showGenderMessage, setShowGenderMessage] = useState<boolean>(false)
    const [ageValue, setAgeValue] = useState<string>(RegistrationData.instance.age)
    const [showAgeMessage, setShowAgeMessage] = useState<boolean>(false)
    const [heightFeetValue, setHeightFeetValue] = useState<string>(RegistrationData.instance.heightFeet)
    const [showFeetMessage, setShowFeetMessage] = useState<boolean>(false)
    const [heightInchesValue, setHeightInchesValue] = useState<string>(RegistrationData.instance.heightInches)
    const [showInchMessage, setShowInchMessage] = useState<boolean>(false)
    const [weightValue, setWeightValue] = useState<string>(RegistrationData.instance.weight)
    const [showWeightMessage, setShowWeightMessage] = useState<boolean>(false)
    const [zipCodeValue, setZipCodeValue] = useState<string>(RegistrationData.instance.zipCode)
    const [showZipCodeMessage, setShowZipCodeMessage] = useState<boolean>(false)
    const [preexistingConditionsValue, setPreexistingConditionsValue] = useState<string[]>(RegistrationData.instance.preexistingCondition)
    const [showPreExistingConditionsMessage, setShowPreExistingConditionsMessage] = useState<boolean>(false)
    const [raceValue, setRaceValue] = useState<string[]>(RegistrationData.instance.race)
    const [showRaceValueMessage, setShowRaceValueMessage] = useState<boolean>(false)
    const [customRace, setCustomRace] = useState<string>(RegistrationData.instance.otherRace)
    const [showCustomRaceMessage, setShowCustomRaceMessage] = useState<boolean>(false)

    function gotoPage(page: PagesEnum) {
        if (NewAccountPageTrigger.instance.setNewAccountShowPage) {
            NewAccountPageTrigger.instance.setNewAccountShowPage(page)
        }
    }
    function nextPage() {
        gotoPage(PagesEnum.Third)
    }
    //change weight question to kg instead of lbs for India registration
    function weightUnit() {
        const country = RegistrationData.instance.providerCountry
        if (country === "USA") {
            return "LB"
        } else {
            return "KG"
        }
    }

    function submit(): void {
        const validateField = (isValid: boolean, setMessage: (arg0: boolean) => void): boolean => {
            if (!isValid) {
                setMessage(true);
                return false;
            }
            setMessage(false);
            return true;
        };

        const validateAllFields = (): boolean => {
            let validate = true;
            validate = validate && validateField(validateInput(firstName), setShowFirstNameMessage);
            validate = validate && validateField(validateInput(lastName), setShowLastNameMessage);
            validate = validate && validateField(validateInput(genderValue), setShowGenderMessage);
            if (raceValue.includes("Other")) {
                validate = validate && validateField(validateInput(customRace), setShowCustomRaceMessage);
            }
            validate = validate && validateField(validateMultiSelectInput(raceValue), setShowRaceValueMessage);
            validate = validate && validateField(validateInput(ageValue), setShowAgeMessage);
            validate = validate && validateField(validateInput(heightFeetValue), setShowFeetMessage);
            validate = validate && validateField(validateInput(heightInchesValue), setShowInchMessage);
            validate = validate && validateField(validateInput(weightValue), setShowWeightMessage);
            validate = validate && validateField(validateInput(zipCodeValue), setShowZipCodeMessage);
            validate = validate && validateField(validateMultiSelectInput(preexistingConditionsValue), setShowPreExistingConditionsMessage);

            return validate;
        };

        // Example usage
        const isValid = validateAllFields();
        if (isValid) {
            nextPage() // Proceed with the next steps, e.g., form submission
        } else {
            // Handle validation errors
        }
    }
    return <div>
        <SpaceBetween size='s' direction='vertical'>
            {/* TODO: put firstname and lastname into the same line */}
            <TextInput
                label="First Name"
                value={firstName}
                onChange={(value) => {
                    setFirstName(value);
                    RegistrationData.instance.firstName = value;
                }}
                placeholder="First Name"
                showMessage = {showFirstNameMessage}/>
            <TextInput
                label="Last Name"
                value={lastName}
                onChange={(value) => {
                    setLastName(value)
                    RegistrationData.instance.lastName = value
                }}
                placeholder="Last Name"
                showMessage = {showLastNameMessage}/>
            <SingleSelectComponent
                question="What is your gender?"
                options={genderOptions}
                value={genderValue}
                onChange={(value) => {
                    setGenderValue(value);
                    RegistrationData.instance.gender = value;
                }}
                showMessage = {showGenderMessage}
            />

            <Multiselect
                label="How would you best describe yourself? (multiple select)"
                options={raceList}
                selectedValue={raceValue}
                onChange={(value) => {
                    setRaceValue(value)
                    RegistrationData.instance.race = value
                }}
                showMessage = {showRaceValueMessage}
            />
            {/* TODO: add condition: If select others */}
            {RegistrationData.instance.race.includes("Other") && <TextInput
                label="Please Specify your race"
                value={customRace}
                onChange={(value) => {
                    setCustomRace(value)
                    RegistrationData.instance.otherRace = value
                }}
                placeholder="Race"
                showMessage = {showCustomRaceMessage}
                />
            }
            <TextInput
                label="Please select your age (0-100)"
                value={ageValue}
                onChange={(value) => {
                    if (isNaN(Number(value))) return
                    const ageNum: number = Number(value)
                    if (ageNum >= 0 && ageNum <= 100) {
                        setAgeValue(value)
                        RegistrationData.instance.age = value
                    }
                }}
                placeholder="Age"
                type="number"
                showMessage = {showAgeMessage}/>


            {/* TODO: put Feet and Inch into the same line */}
            {/*height*/}
            <SpaceBetween size='m' direction='horizontal'>
                <div>Please select your height</div>
            </SpaceBetween>
            <TextInput
                value={heightFeetValue}
                onChange={(value) => {
                    if (isNaN(Number(value))) return
                    const feetNum: number = Number(value)
                    if (feetNum >= 0 && feetNum <= 7) {
                        setHeightFeetValue(value)
                        RegistrationData.instance.heightFeet = value
                    }
                }}
                placeholder="Feet"
                type="number"
                showMessage = {showFeetMessage}/>
            <TextInput
                value={heightInchesValue}
                onChange={(value) => {
                    if (isNaN(Number(value))) return
                    const ageNum: number = Number(value)
                    if (ageNum >= 0 && ageNum <= 11) {
                        setHeightInchesValue(value)
                        RegistrationData.instance.heightInches = value
                    }
                }}
                placeholder="Inches"
                type="number"
                showMessage = {showInchMessage}/>

            {/*weight*/}
            <TextInput
                label="Please select your weight"
                value={weightValue}

                onChange={(value) => {
                    if (isNaN(Number(value))) return
                    const ageNum: number = Number(value)
                    if (ageNum >= 0 && ageNum <= 400) {
                        setWeightValue(value)
                        RegistrationData.instance.weight = value
                    }
                }}
                placeholder={weightUnit()}
                type="number"
                showMessage = {showWeightMessage}
                message="Weight can't be empty"
                />


            {/*zip code*/}
            <TextInput
                label="Please enter your zip code"
                value={zipCodeValue}

                onChange={(value) => {
                    setZipCodeValue(value)
                    RegistrationData.instance.zipCode = value
                }}
                placeholder="Zip Code"
                type="number"
                showMessage = {showZipCodeMessage}/>

            <Multiselect
                label="Please select any preexisting conditions you have"
                options={preExistingConditions}
                selectedValue={preexistingConditionsValue}
                onChange={(value) => {
                    setPreexistingConditionsValue(value)
                    RegistrationData.instance.preexistingCondition = value
                }
                }
                showMessage = {showPreExistingConditionsMessage}
                />

            <div className='line-align-center'>
                <Button className='line-items-padding' onClick={() => { gotoPage(PagesEnum.First) }}>Back</Button>
                <Button variant='primary' className='line-items-padding' onClick={submit}>Next</Button>
            </div>
        </SpaceBetween>
    </div>
}
