import { useEffect, useState } from "react";
import { SpaceBetween } from "@cloudscape-design/components";

import { NewAccountPageTrigger } from './PageTrigger'
import { useParams } from "react-router";
import { PagesEnum } from "./PageTrigger";
import { ENDPOINT, RegistrationData} from "../../../../data/FormData";
import { support_contact } from "../../../../res/support"
import { renderProviderLogo } from '../../../../components/ProviderLogo';
import { renderPowerBy } from "../../../../components/PowerBy";
const support: JSX.Element = support_contact

// Component to render questionnaire pages based on registration token
export function QuestionnairePagesContainer(): JSX.Element {
    const { token } = useParams()
    const [showPage, setShowPage] = useState<PagesEnum>(PagesEnum.First);
    const [showPageContent, setShowPageContent] = useState<JSX.Element>(<div />);

    useEffect(() => {

        fetch(`${ENDPOINT}/validregistrationtoken?token=${token}`)
            .then(response => {
                if (response.status === 200) {
                    let data = response.json()
                    console.log(data)
                    return data;
                } else {
                    // the server will reponse with error if the token is not valid
                    const invalidPage: JSX.Element = <div style={{ marginTop: '30px' }}>
                        <p>This is not a valid registration link. </p>
                        {support}
                    </div>
                    setShowPageContent(invalidPage)
                    return
                }
            }).then(data => {
                //email
                RegistrationData.instance.email= data["email"]
                RegistrationData.instance.token = token!
                //provider info
                RegistrationData.instance.providerID = data["provider"]["provider"]
                RegistrationData.instance.providerName = data["provider"]["name"]
                RegistrationData.instance.providerSupportEmail = data["provider"]["support_email"]
                RegistrationData.instance.providerSupportCell = data["provider"]["support_cell"]
                RegistrationData.instance.providerCountry = data["provider"]["country"]
                //questionnaire info
                //  Are you taking (HTN/DM) medications
                RegistrationData.instance.show_htn_med_question = data["show_htn_med_question"]
                RegistrationData.instance.show_dm_med_question = data["show_dm_med_question"]

                //  Do you own a wearable device
                RegistrationData.instance.show_wearable_device_question = data["show_wearable_device_question"]
                RegistrationData.instance.provide_device_option = data["provide_device_option"]
                RegistrationData.instance.opt_out_wearable_option = data["opt_out_wearable_option"]

                //  Do you own a BP Cuff
                RegistrationData.instance.show_bp_cuff_question = data["show_bp_cuff_question"]
                RegistrationData.instance.provide_bp_cuff_option = data["provide_bp_cuff_option"]
                RegistrationData.instance.bp_cuff_require_bluetooth_option = data["bp_cuff_require_bluetooth_option"]

                //  Do you own a Glucometer
                RegistrationData.instance.show_glucometer_question = data["show_glucometer_question"]
                RegistrationData.instance.provide_glucometer_option = data["provide_glucometer_option"]

                // Set the page to be displayed
                NewAccountPageTrigger.instance.setNewAccountShowPage = (page: PagesEnum): void => {
                    setShowPage(page)
                }
                setShowPageContent(NewAccountPageTrigger.instance.getShowPage(showPage))
            })
            .catch(err => {
                console.log(err)
            })

    }, [token, showPage])

    return (
        <div className="App center-screen">
            <div className="glow-border page-container" style={{ width: "330px" }}>
                <SpaceBetween size='xs' direction='vertical'>
                    {RegistrationData.instance.providerID ? renderProviderLogo({ provider: RegistrationData.instance.providerID.toUpperCase() }) : null}
                    <div>
                        <h3 className='line-align-center' style={{ marginBottom: '0' }}>Tailored Care and Prevention of Chronic Conditions</h3>
                    </div>
                    {showPageContent}
                    {RegistrationData.instance.providerID ? renderPowerBy({ provider: RegistrationData.instance.providerID.toUpperCase() }) : null}
                </SpaceBetween>
            </div>
        </div>
    );
}
export default QuestionnairePagesContainer