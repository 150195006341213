import React from 'react';
import { SpaceBetween, Checkbox } from "@cloudscape-design/components";
import { CheckboxGroup, CheckboxItem } from "./CheckboxGroup"; // Adjust the import based on your file structure

interface MultiselectProps {
    label: string;
    options: { value: string, label: string }[];
    selectedValue: string[];
    onChange: (value: string[]) => void;
    showMessage?: boolean;
    message?: string; // Add a message prop for customization
}

export const Multiselect: React.FC<MultiselectProps> = ({ 
    label, 
    options, 
    selectedValue, 
    onChange,
    showMessage = false,
    message // Receive the custom message as a prop
}) => {

    // Convert options to CheckboxItem format expected by CheckboxGroup
    const checkboxItems: CheckboxItem[] = options.map(option => ({
        key: option.value,
        text: option.label,
        checked: selectedValue.includes(option.value)
    }));

    // Use the custom message if provided, otherwise use the default message
    const defaultMessage = "Please make your selections";
    const displayMessage = message || defaultMessage;
    const handleOptionChange = (updatedOptions: CheckboxItem[]) => {
        // Convert CheckboxItem back to the format expected by selectedValue
        const updatedValues = updatedOptions
            .filter(item => item.checked)
            .map(item => item.key);

        onChange(updatedValues);
    };

    return (
        <SpaceBetween size='m' direction='vertical'>
            <SpaceBetween size='m' direction='horizontal'>
                <div>{label}</div>
            </SpaceBetween>
            <CheckboxGroup
                content={checkboxItems}
                setContent={handleOptionChange}
            />
            {showMessage && <p className='tip-text'>{displayMessage}</p>}
        </SpaceBetween>
        
    );
};
