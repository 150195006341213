// RadioGroupComponent.tsx
import React from 'react';
import { SpaceBetween, RadioGroup as CloudscapeRadioGroup } from '@cloudscape-design/components';

interface Option {
    value: string;
    label: string;
    show?: boolean; // make show optional
}

interface SingleSelectProps {
    question: string;
    options: Option[];
    value: string;
    onChange: (value: string) => void;
    showMessage?: boolean;
}

const SingleSelectComponent: React.FC<SingleSelectProps> = ({ 
    question, 
    options, 
    value, 
    onChange, 
    showMessage = false 
}) => {
    const handleChange = (event: { detail: { value: string } }) => {
        onChange(event.detail.value);
    };

    // Set show to true by default if not provided and filter options
    const filteredOptions = options
        .map(option => ({ ...option, show: option.show ?? true })) // Default show to true
        .filter(option => option.show);

    return (
        <SpaceBetween size='s' direction='vertical'>
            <SpaceBetween size='m' direction='horizontal'>
                <div className='question-text'>{question}</div>
            </SpaceBetween>
            <CloudscapeRadioGroup
                onChange={handleChange}
                value={value}
                items={filteredOptions}
            />
            {showMessage && <p className='tip-text'>Please make a selection.</p>}
        </SpaceBetween>
    );
};

export default SingleSelectComponent;
