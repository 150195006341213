import React from 'react';
import cipraLogo from '../res/ProviderLogos/cipra_logo.jpg'
import phsoLogo from "../res/ProviderLogos/phso-logo.png";
import cdxLogo from "../res/ProviderLogos/cdx-logo.png";
import h2050Logo from "../res/ProviderLogos/h2050-logo.jpg";
import nhcLogo from "../res/ProviderLogos/nhc-logo.jpg";
import rxdxLogo from "../res/ProviderLogos/rxdx-logo.png";
import cabLogo from '../res/ProviderLogos/cab_logo.png';

interface ProviderLogoProps {
    provider?: string; // make provider optional
}

export const renderProviderLogo: React.FC<ProviderLogoProps> = ({ provider }) => {
    const uppercaseProvider = provider?.toUpperCase();
    if (uppercaseProvider === "CDX") {
        return <img src={cdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
    } else if (uppercaseProvider === "H2050") {
        return <img src={h2050Logo} alt='provider logo' width='90%' className='line-align-center' />;
    } else if (uppercaseProvider === "NHC") {
        return <img src={nhcLogo} alt='provider logo' width='90%' className='line-align-center' />;
    } else if (uppercaseProvider === "PHSO" || uppercaseProvider === "PHSO_BP") {
        return <img src={phsoLogo} alt='provider logo' width='90%' className='line-align-center' />;
    } else if (uppercaseProvider === "RXDX") {
        return <img src={rxdxLogo} alt='provider logo' width='90%' className='line-align-center' />;
    } else if (uppercaseProvider === "CAB"){
        return <img src={cabLogo} alt='provider logo' width='90%' className='line-align-center' />;
    } else {
        return <img src={cipraLogo} alt='provider logo' width='90%' className='line-align-center' />;
    }
};
