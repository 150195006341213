export const DEBUG_MODE = false
export const ENDPOINT = 'https://api.cipra.ai:5000'
// export const ENDPOINT = 'http://127.0.0.1:8000'

export class RegistrationData {
    static instance: RegistrationData = new RegistrationData();
    token:string = '';
    cell: string = '';
    password: string = '';
    firstName: string = '';
    lastName: string = '';
    gender: string = '';
    age: string = '';
    heightFeet: string = '';
    heightInches: string = '';
    weight: string = '';
    zipCode: string = '';
    preexistingCondition: string[] = [];
    race:string[] = [];
    otherRace: string = "";
    providerSupportEmail: string = "";
    providerSupportCell: string = "";
    bloodPressureMedication: string = '';
    dmMedication: string = '';
    phoneType: 'Apple' | 'Android' | '' = '';
    deviceType: 'Apple Watch' | 'Fitbit'| 'No'| 'opt-out'|'' = '';
    bloodPressureMonitor: string = '';
    glucometer: string = '';
    address: string = '';
    addressCity: string = '';
    addressState: string = '';
    addressZipcode: string = '';

    getFullAddress(): string {
        const fullAddress = `${this.address}\n${this.addressCity}, ${this.addressState} ${this.addressZipcode}`
        return fullAddress === '\n,  ' ? '' : fullAddress
    }
    getRace():string[]{
        if (this.race.includes("Other")){
            return this.race.map(race => race === "Other" ? this.otherRace : race)
        }else{
            return this.race
        }
    }

    providerID: string = "";
    providerName: string = ""
    email:string = ""
    providerCountry: string = ""
    //  Are you taking (HTN/DM) medications
    show_htn_med_question: boolean = false;
    show_dm_med_question: boolean = false;

    //  Do you own a wearable device
    show_wearable_device_question: boolean = false;
    provide_device_option : boolean = false;
    opt_out_wearable_option : boolean = false;

    //  Do you own a BP Cuff
    show_bp_cuff_question : boolean = false;
    provide_bp_cuff_option : boolean = false;
    bp_cuff_require_bluetooth_option: boolean = false;

    //  Do you own a Glucometer
    show_glucometer_question : boolean = false;
    provide_glucometer_option : boolean = false;

    submit(callbackSuccess: () => void, callbackFail: () => void): void {
        const submitData = {
            token: this.token,
            first_name: this.firstName,
            last_name: this.lastName,
            password: this.password,
            cell: '+' + this.cell,
            htn_med: this.bloodPressureMedication,
            dm_med: this.dmMedication,
            os: this.phoneType,
            device: this.deviceType,
            bp_cuff: this.bloodPressureMonitor,
            glucometer: this.glucometer,
            age: this.age,
            gender: this.gender,
            // send a list instead
            race: this.getRace(),
            // if user doesn't enter a height value, then it should be an empty string
            height: (Number(this.heightFeet) === 0 && Number(this.heightInches) === 0) ?
                    "" : String(Number(this.heightFeet) * 12 + Number(this.heightInches)),
            weight: this.weight,
            zip_code: this.zipCode,
            // send a list instead
            conditions: this.preexistingCondition,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            address: this.getFullAddress(),
        }

        console.log(submitData)
        if(DEBUG_MODE) return

        fetch(`${ENDPOINT}/newaccount`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
        })
            .then(response => {
                console.log(response)
                // TODO: 500 server error
                callbackSuccess()
            })
            .catch(e => {
                console.log('Network Issue: ' + e)
                callbackFail()
            })
    }
}
