import cipraLogo from "../../res/ProviderLogos/cipra_logo.jpg";
import {Button, Input, SpaceBetween,Spinner} from "@cloudscape-design/components";
import {useEffect, useState, useCallback} from "react";
export function OnBoardingPage(): JSX.Element {

    const [emailValue, setEmailValue] = useState<string>('')
    const [emailTip, setEmailTip] = useState<JSX.Element | undefined>(undefined)
    const [showBlock, setShowBlock] = useState<boolean>(true)
    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)

    const submit = useCallback((): void => {
        setSubmitWaiting(true)
        if (emailValue.length === 0){
            setSubmitWaiting(false)
            setEmailTip(
                <div>
                    No Email Provided.
                </div>
            )
            return
        }
        const url = 'https://api.cipra.ai:5000/sendonboarding?email='+emailValue 
        fetch(url)
            .then(response => {
                if(response.ok){
                    setSubmitWaiting(false)
                    setShowBlock(false);
                    setEmailTip(
                        <div>
                            Onboarding email has been sent to <b>{emailValue}</b>, please check your email inbox for the next step.
                        </div>
                    )
                } else {
                    setSubmitWaiting(false)
                    setEmailTip(
                        <div>
                            Email not registered.
                        </div>
                    )
                }
            })
            .catch(err => {
                console.log(err);
                
            })
    }, [emailValue])

    useEffect(() => {

        const enterKeyDown = (event: KeyboardEvent) => {
            if(event.key !== 'Enter') return
            submit()
        }

        document.addEventListener("keydown", enterKeyDown, false);

        return () => {
            document.removeEventListener("keydown", enterKeyDown, false);
        };
    }, [submit]);

    return <div className="center-screen">
        <div className="glow-border page-container" style={{maxWidth: "330px"}}>
            <SpaceBetween size='m' direction='vertical'>
                <img src={cipraLogo} alt='cipra logo' width='90%' className='line-align-center'/>
                <h3 className='line-align-center'>Enter your email to receive your personalized onboarding guide</h3>
                {
                    (showBlock) &&
                    <SpaceBetween size='m' direction='vertical'>
                        <SpaceBetween size='m' direction='horizontal'>
                            <div>Email</div>
                        </SpaceBetween>
                        <Input onChange={({detail}) => setEmailValue(detail.value)}
                               value={emailValue}
                               type="email"
                               placeholder="Email Address"
                        ></Input>
                        {(emailTip) && <p className='tip-text'>{emailTip}</p>}
                        <div className='line-align-center'>
                            <Button variant='primary' onClick={submit}  disabled={submitWaiting}>
                            {
                            submitWaiting && <Spinner />
                            }
                            Confirm
                            </Button>
                        </div>
                    </SpaceBetween>
                }
                {
                    (!showBlock) &&
                    <div>
                        <p>{emailTip}</p>
                    </div>
                }
            </SpaceBetween>
        </div>
    </div>
}
