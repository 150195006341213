import {ENDPOINT} from "./FormData";

export class ResetPasswordData {
    static instance: ResetPasswordData = new ResetPasswordData()

    EndpointEmailExist = `${ENDPOINT}/emailexists/`
    EndpointSendEmail = `${ENDPOINT}/sendresetpasswordurl/v2?`
    //https://api.cipra.ai:5000/sendresetpasswordurl/v2?email=jared.leitner@gmail.com&patientID=9999 
    EndpointResetPassword = `${ENDPOINT}/resetpassword/v2`

    emailExistv2(
        emailAddress: string,
        whenExist: (data:any) => void,
        whenNotExist: () => void
    ): void {
        const url = this.EndpointEmailExist + emailAddress;
        fetch(url)
        .then(response => {
            return response.json();
        })
        .then(data => {
            // Call the provided callback with the parsed JSON content
            if (data.email_exists) {
                whenExist(data);
            } else {
                whenNotExist();
            }
            
        })
        .catch(err => {
            console.log(err)
        })
    }

    sendEmail(
        emailAddress: string,
        patientID:string,
        whenSuccess: () => void,
        whenFailed: () => void
    ): void {
        const url = this.EndpointSendEmail + 'email='+emailAddress + '&patientID=' + patientID;
        fetch(url)
            .then(response => {
                if(response.ok){
                    whenSuccess()
                } else {
                    whenFailed()
                }
            })
            .catch(err => {
                console.log(err);
                whenFailed();
            })
    }

    submit(token: string, password: string, callbackSuccess: () => void, callbackFail: () => void): void {
        const submitData = {
            token: token,
            password: password
        }
        fetch(this.EndpointResetPassword, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
        })
            .then(response => {
                console.log(response)
                callbackSuccess()
            })
            .catch(e => {
                console.log('Network Issue: ' + e)
                callbackFail()
            })
        console.log("submitted", submitData)
    }
}
