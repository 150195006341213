import React from 'react';
import { SpaceBetween } from "@cloudscape-design/components";

interface DropdownBoxProps {
    label: string;
    options: { value: string, label: string }[];
    selectedValue: string;
    onChange: (value: string) => void;
    showMessage?: boolean;
    message?: string; // Add a message prop for customization
}

export const DropdownBox: React.FC<DropdownBoxProps> = ({ 
    label, 
    options, 
    selectedValue, 
    onChange,
    showMessage = false,
    message // Receive the custom message as a prop
    }) => {
        // Use the custom message if provided, otherwise use the default message
     const defaultMessage = `Please make a selection`;
     const displayMessage = message || defaultMessage;
        return(
    <SpaceBetween size='m' direction='vertical'>
        <SpaceBetween size='m' direction='horizontal'>
            <div>{label}</div>
        </SpaceBetween>
        <select 
            value={selectedValue} 
            onChange={(e) => onChange(e.target.value)}
            style={{ color: 'black', padding: '7px', borderRadius: '7px', width: '100%', border: '2px solid gray' }}
        >
            <option value=""></option>
            {options.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </select>
        {showMessage && <p className='tip-text'>{displayMessage}</p>}
    </SpaceBetween>
);}
