import { Button, Input, NonCancelableCustomEvent, SpaceBetween, Spinner } from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { ResetPasswordData } from "../../data/ResetPasswordData";
import cipraLogo from "../../res/ProviderLogos/cipra_logo.jpg";
import { useParams } from "react-router";
import { ENDPOINT } from "../../data/FormData";
import { BaseChangeDetail } from "@cloudscape-design/components/input/interfaces";
import $ from 'jquery';
import {support_contact} from "../../res/support"
const support: JSX.Element = support_contact
export function PasswordResetNewPasswordPage(): JSX.Element {
    const { token } = useParams()
    const [showBlock, setShowBlock] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(true)
    const [validToken, setValidToken] = useState<boolean>(true)
    const [passwordValue, setPasswordValue] = useState<string>('')
    const [confirmedPasswordValue, setConfirmedPasswordValue] = useState<string>('')
    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)
    const [passwordTip, setPasswordTip] = useState<string | undefined>('')
    const [requirementsMet, setRequirementsMet] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        special:false
    });
    const checkPasswordRequirements = (password: string) => {
        setRequirementsMet({
            length: password.length >= 8 && password.length <= 25,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            digit: /[0-9]/.test(password),
            special:/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password)
        });
    };
    useEffect(() => {
        fetch(`${ENDPOINT}/validaccountmanagementtoken?token=${token}&token_type=reset_password`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                // Call the provided callback with the parsed JSON content
                if (data.valid_token) {
                    setValidToken(true);
                } else {
                    setValidToken(false)
                }

            })
            .catch(err => {
                console.log(err)
            })
        // Example: Add a click event listener to an element with class 'my-element'
        $('input[type=password]:first').keyup(function() {// only show the password requirement box when the password input is focused
            // keyup code here
           }).focus(function() {
            $('#pswd_info').show();
           }).blur(function() {
            $('#pswd_info').hide();
           });
    }, [token])

    function handlePasswordChange(event: NonCancelableCustomEvent<BaseChangeDetail>): void {
        setPasswordValue(event.detail.value)
        checkPasswordRequirements(event.detail.value);
        setPasswordTip(undefined)
    }
    function handleConfirmedPasswordChange(event: NonCancelableCustomEvent<BaseChangeDetail>): void {
        setConfirmedPasswordValue(event.detail.value)
        setPasswordTip(undefined)
    }
    function submit(): void {
        setSubmitWaiting(true)
        console.log("email", token)
        if (!token) return
        // check if the password meet the requirement
        var passwordMeetRequirement = false
        if (passwordValue.length === 0) {
            setPasswordTip("Password cann't be empty.")
        } else if (passwordValue.length > 25) {
            setPasswordTip("Your password is too long. Please choose a password that is no more than 15 characters long")
        } else if (passwordValue.length < 8) {
            setPasswordTip("Your password is too short. Please choose a password that is at least 8 characters long.")
        } else if (! /[A-Z]/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one uppercase letter.")
        } else if (!/[a-z]/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one lowercase letter.")
        } else if (!/\d/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one numeric digit.")
        } else if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(passwordValue)) {
            setPasswordTip("Password must contain at least one special character.")
        } else if (passwordValue !== confirmedPasswordValue) {
            setPasswordTip("Password not the same")
        } else { // all requirements are meet
            setPasswordTip(undefined)
            passwordMeetRequirement = true
        }
        if (!passwordMeetRequirement) {
            setSubmitWaiting(false)
            return
        }
        ResetPasswordData.instance.submit(
            token!,
            passwordValue,
            () => {
                setSubmitWaiting(true)
                setShowBlock(false)
            },
            () => {
                setSubmitWaiting(true)
                setShowBlock(false)
                console.log('Network Error')
                setSuccess(false)
            }
        )
    }

    return <div className='center-screen'>
        <div className='glow-border page-container page-width-constrain'>
            <SpaceBetween size='m' direction='vertical'>
                <img src={cipraLogo} alt='cipra logo' width='90%' className='line-align-center' />
                <h2 className='line-align-center'>Reset Password</h2>
                {
                    (!validToken) &&
                    <div>
                        <p>This link has expired. Please request a new link <a href="http://account.cipra.ai/forgotPassword">here</a>.</p>
                    </div>

                }
                {
                    (validToken && showBlock) &&
                    <SpaceBetween size='m' direction='vertical'>
                        <SpaceBetween size='m' direction='horizontal'>
                            <div>Password</div>
                        </SpaceBetween>
                        <Input onChange={handlePasswordChange}
                            value={passwordValue}
                            type="password"
                            placeholder="Password"
                        ></Input>
                        <div id="pswd_info">
                {/* Display password requirements */}
                <h4>Password must meet the following requirements:</h4>
                <ul className="password-requirements">
                    <li className={requirementsMet.length ? 'met' : 'not-met'}>At least 8 characters. At most 25 characters</li>
                    <li className={requirementsMet.uppercase ? 'met' : 'not-met'}>At least one uppercase letter</li>
                    <li className={requirementsMet.lowercase ? 'met' : 'not-met'}>At least one lowercase letter</li>
                    <li className={requirementsMet.digit ? 'met' : 'not-met'}>At least one digit</li>
                    <li className={requirementsMet.special ? 'met' : 'not-met'}>At least one special character</li>
                </ul>
            </div>
                        <SpaceBetween size='m' direction='horizontal'>
                            <div>Confirmed Password</div>
                        </SpaceBetween>
                        <Input onChange={handleConfirmedPasswordChange}
                            value={confirmedPasswordValue}
                            type="password"
                            placeholder="Confirmed Password"
                        ></Input>
                        {
                            (passwordTip) && <p className='tip-text'>{passwordTip}</p>
                        }
                        <div className='line-align-center'>
                            <Button variant='primary' onClick={submit} disabled={submitWaiting}>
                                {
                                    submitWaiting && <Spinner />
                                }
                                Submit
                            </Button>
                        </div>
                    </SpaceBetween>
                }
                {
                    !showBlock && success &&
                    <div>
                        <p className='line-align-center'>Password has been reset.</p>
                    </div>
                }
                {
                    !showBlock && !success &&
                    <div>
                        <p className='line-align-center'>Your request can't be processed. </p>
                        {support}
                    </div>
                }
            </SpaceBetween>
        </div>
    </div>
}
export default PasswordResetNewPasswordPage