import cipraLogo from '../res/ProviderLogos/cipra_logo.jpg'

interface PowerByProps{
    provider?: string; // make provider optional
}

export const renderPowerBy: React.FC<PowerByProps> = ({ provider }) => {
    if (provider === "CDX" || provider === "H2050" || provider === "NHC" || provider === "PHSO" || provider === "RXDX" || provider === "CAB") {
        return (
          <div className='line-align-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ marginRight: '10px' }}>Enabled by:</p>
            <img src={cipraLogo} alt="Description" style={{ width: 'auto', maxWidth: '100%', height: '30px' }} />
          </div>
        );
      } else {
        return null;
      }
};