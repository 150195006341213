import React from 'react';
import { Input, SpaceBetween } from "@cloudscape-design/components";

interface TextInputProps {
    label?: string;
    value: string;
    onChange: (value: string) => void;
    type?: 'text' | 'password' | 'email' | 'number'; // Add other types if necessary
    placeholder?: string;
    showMessage?: boolean;
    disabled?: boolean;
    message?: string; // Add a message prop for customization
}


export const TextInput: React.FC<TextInputProps> = ({
    label,
    value,
    onChange,
    type = "text",
    placeholder,
    showMessage = false,
    disabled = false, // Provide a default value
    message, // Receive the custom message as a prop
}) => {
    const handleChange = (event: { detail: { value: string } }) => {
        onChange(event.detail.value);
    };

     // Use the custom message if provided, otherwise use the default message
     const defaultMessage = `${placeholder} value can't be empty`;
     const displayMessage = message || defaultMessage;

    return (
        <SpaceBetween size='m' direction='vertical'>
            <SpaceBetween size='m' direction='horizontal'>
                <div>{label}</div>
            </SpaceBetween>
            <Input 
                onChange={handleChange}
                value={value}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />
            {showMessage && <p className='tip-text'>{displayMessage}</p>}
        </SpaceBetween>
    )
    
}