export const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "preferNotToSay", label: "Prefer not to say" }
];

export const binaryOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" }
]

export const preExistingConditions  = [
    {value: 'Diabetes', label: 'Diabetes'},
    {value: 'Hypertension', label: 'Hypertension'},
    {value: 'LiverDisease', label: 'Liver Disease'},
    {value: 'LungDisease', label: 'Lung Disease'},
    {value: 'SleepApnea', label: 'Sleep Apnea'},
    {value: 'NoneOfAbove', label: 'None of above'}
]

export const raceList = [
    {value: 'American Indian or Alaskan Native', label: 'American Indian or Alaskan Native'},
    {value: 'Asian / Pacific Islander', label: 'Asian / Pacific Islander'},
    {value: 'Black or African American', label: 'Black or African American'},
    {value: 'Hispanic', label: 'Hispanic'},
    {value: 'White / Caucasian', label: 'White / Caucasian'},
    {value: 'Other', label: 'Other (please specify)'}
]

export const phoneList = [
    { value: 'Apple', label: 'Apple' },
    { value: 'Android', label: 'Android' }
]
