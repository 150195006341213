export const validateInput = (value: string): boolean => {
    if (value.length === 0) {
        return false;
    }
    return true;
};

export const validateMultiSelectInput = (value:string[]): boolean =>{
    console.log(value)
    if (value.length === 0) {
        return false;
    }
    return true;
}