import {ENDPOINT} from "./FormData";

export class RegistrationData {
    static instance: RegistrationData = new RegistrationData()

    EndpointEmailExist = `${ENDPOINT}/emailexists/`

    emailExist(
        emailAddress: string,
        whenNotExist: (data:any) => void,
        whenExist: () => void
    ): void {
        const url = this.EndpointEmailExist + emailAddress;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                // Call the provided callback with the parsed JSON content
                if (data.email_exists) {
                    whenExist();
                } else {
                    whenNotExist(data);
                }
                
            })
            .catch(err => {
                console.log(err)
            })
    }

    sendEmail(
        emailAddress: string,
        provider:string,
        programs:string[],
        subscription:string,
        language:string,
        callbackSuccess: () => void, callbackFail: () => void
    ): void {
        const submitData = {
            email : emailAddress,
            provider : provider,
            subscription : subscription,
            language_id : language,
            programs : programs
        }
        console.log(submitData)

        fetch(`${ENDPOINT}/generateregistrationurl`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
        })
            .then(response => {
                console.log(response)
                callbackSuccess()
            })
            .catch(e => {
                console.log('Network Issue: ' + e)
                callbackFail()
            })
    }
}
