import { useEffect, useState, useCallback } from "react";
import '../../__pages.css'
import { ENDPOINT } from "../../../data/FormData";
import { useParams } from "react-router";
import { Button, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { renderProviderLogo } from "../../../components/ProviderLogo";
import { RegistrationData } from "../../../data/RegistrationData";
import { support_contact } from "../../../res/support"
import { TextInput } from "../../../components/TextInput";
import { DropdownBox } from "../../../components/DropdownBox";
import { validateInput, validateMultiSelectInput } from "../../../utility-function/InputValidation";
import { Multiselect } from "../../../components/Multiselect";
import { renderPowerBy } from "../../../components/PowerBy";
const support: JSX.Element = support_contact
function PatientRegistrationMultiProgram(): JSX.Element {
    const [emailValue, setEmailValue] = useState<string>('')
    const [showEmailMessage, setShowEmailMessage] = useState<boolean>(false)
    const [message, setMessage] = useState<JSX.Element | undefined>(undefined)
    const [showBlock, setShowBlock] = useState<boolean>(true)
    const [validProvider, setValidProvider] = useState<boolean>(true);
    const { provider } = useParams()
    const [selectedLanguage, setSelectedLanguage] = useState<string>('')
    const [showLanguageMessage, setShowLanguageMessage] = useState<boolean>(false)
    const [response, setResponse] = useState<JsonData | null>(null)
    const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
    const [showProgramMessage, setShowProgramMessage] = useState<boolean>(false)

    const handleSelectionChange = (newSelectedValues: string[]) => {
        setSelectedPrograms(newSelectedValues);
    };

    // define the type of jsonData using an interface according to the API response
    interface JsonData {
        languages: { language_id: string, language_name: string }[];
        programs: { program: string, name: string }[];
    }

    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false)
    const submit = useCallback((): void => {
        const isLanguageValid = validateInput(selectedLanguage);
        const isEmailValid = validateInput(emailValue);
        const isProgramsValid = validateMultiSelectInput(selectedPrograms);

        setShowLanguageMessage(!isLanguageValid);
        setShowEmailMessage(!isEmailValid);
        setShowProgramMessage(!isProgramsValid);

        if (!isLanguageValid || !isEmailValid || !isProgramsValid) {
            return;
        }

        setSubmitWaiting(true)
        RegistrationData.instance.emailExist(
            emailValue,
            () => {
                RegistrationData.instance.sendEmail(
                    emailValue,
                    provider!,
                    selectedPrograms,
                    "",// subscription
                    selectedLanguage,//language
                    () => {
                        setSubmitWaiting(false)
                        setShowBlock(false);
                        setMessage(
                            <div style={{ color: 'green' }}>
                                Registration Link has been sent to <b>{emailValue}</b>, please check your email inbox for the next step.
                            </div>
                        )
                    },
                    () => {
                        setSubmitWaiting(false)
                        setMessage(
                            <div>
                                Request cannot be processed at the moment.
                            </div>
                        )
                    }
                )
            },
            // Email already registered.
            () => {
                setSubmitWaiting(false)
                setMessage(
                    <div>
                        <p>This email address <b>{emailValue} has already been registered.</b></p>
                        {support}
                    </div>
                )
            }
        )
    }, [emailValue, selectedLanguage, provider, selectedPrograms])
    useEffect(() => {
        console.log("loading page")
        // check from api - if the provider or program is valid  
        fetch(`${ENDPOINT}/validprovider/${provider}`).then(response => {
            if (!response.ok) {// provider is valid
                setValidProvider(false)
                setShowBlock(false)
            }
            return response.json()
        })
            .then(data => {
                // Call the provided callback with the parsed JSON content
                setResponse(data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [provider])

    return (
        <div className="center-screen">
            <div className="glow-border page-container" style={{ maxWidth: "330px" }}>
                <SpaceBetween size='m' direction='vertical'>
                    {provider ? renderProviderLogo({ provider }) : null}
                    <h2 className='line-align-center'>Enter Email to Receive Registration Link</h2>
                    {
                        (!validProvider) &&
                        <div>
                            <p>This is not a valid registration link.</p>
                            {support}
                        </div>
                    }
                    {
                        (showBlock) && (response) &&
                        <SpaceBetween size='m' direction='vertical'>
                            <TextInput
                                label="Email"
                                value={emailValue}
                                onChange={setEmailValue}
                                type="email"
                                placeholder="Email Address"
                                showMessage={showEmailMessage}
                            />
                            <DropdownBox
                                label="Select a Language"
                                options={response.languages.map(language => ({ value: language.language_id, label: language.language_name }))}
                                selectedValue={selectedLanguage}
                                onChange={setSelectedLanguage}
                                showMessage = {showLanguageMessage}
                            />
                            <Multiselect
                                label="Select Programs"
                                options={response.programs.map(program => ({ value: program.program, label: `${program.name} (${program.program})` }))}
                                selectedValue={selectedPrograms}
                                onChange={handleSelectionChange}
                                showMessage={showProgramMessage} />
                            <div className='line-align-center'>
                                <Button variant='primary' onClick={submit} disabled={submitWaiting}>
                                    {
                                        submitWaiting && <Spinner />
                                    }
                                    Confirm
                                </Button>
                            </div>
                        </SpaceBetween>
                    }
                    {message && <p className='tip-text'>{message}</p>}

                    {provider ? renderPowerBy({ provider: provider?.toUpperCase() }) : null}
                </SpaceBetween>
            </div>
        </div>
    );
}

export default PatientRegistrationMultiProgram;
